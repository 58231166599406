#adminApp

  .badge-visit {
    color: #fff;
    background-color: #563d7c;  
  }

  .text-visit {
    color: #563d7c;
  }

  .text-lead {
    @extend .text-info
  }

  .metadata-tags-container{
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s;
    background-color: rgba(0,0,0,0.6);
  }

  .metadata-tags-container:hover{
    opacity: 1;
  }

  .google-map-edit {
      position: relative;
  }

  .google-map-edit:after {
      width: 22px;
      height: 32px;
      display: block;
      content: ' ';
      position: absolute;
      top: 50%; left: 50%;
      margin: -32px 0 0 -11px;
      background: url('https://maps.gstatic.com/mapfiles/markers2/arrow.png');
      pointer-events: none; /* This disables clicks on the marker. Not fully supported by all major browsers, though */
  }
