

$blue:    #4152f3 !default;
$green:   #00cdc3 !default;
$red:     #f24916 !default;
$yellow:  #f3c403 !default;

@import "~bootswatch/dist/materia/variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/materia/bootswatch";

@import "./css/explorer_app.scss";
@import "./css/admin_app.scss";
@import "./css/validator_app.scss";

@import "./css/overrides.scss";